import { useState } from "react";
import { NavLink } from "react-router-dom";
import "./MobileMore.css";
import { awanaPath, ezraPath, moreString } from "../../../constants";
import { useAppSelector } from "../../../app/hooks";
import { selectLanguage } from "../../../app/slices/languageSlice";

const MobileMore = (props) => {
  const [displayMore, setDisplayMore] = useState(false);
  const language = useAppSelector(selectLanguage);

  const toggleMore = () => {
    setDisplayMore(displayMore === true ? false : true);
  };

  return (
    <div className="moreContainer">
      <div className="more" onClick={toggleMore}>
        {moreString(language)}
      </div>
      {displayMore && (
        <ul>
          <li>
            <NavLink
              activeclassname="active"
              onClick={props.handleClick}
              to={awanaPath}
            >
              Awana
            </NavLink>
          </li>
          <li>
            <NavLink
              activeclassname="active"
              onClick={props.handleClick}
              to={ezraPath}
            >
              Ezra Conference
            </NavLink>
          </li>
        </ul>
      )}
    </div>
  );
};

export default MobileMore;
