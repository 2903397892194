import "./Bethel.css";
import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./pages/about/about";
import Services from "./pages/services/services";
import Home from "./pages/home/home";
import Contact from "./pages/contact/contact";
import Announcements from "./pages/announcements/announcements";
import Menu from "./menu/Menu";
import ErrorPage from "./pages/error/errorPage";
import RefundPolicy from "./pages/refundPolicy/refundPolicy";
import PrivacyPolicy from "./pages/privacyPolicy/privacyPolicy";
import Footer from "./footer/footer";
import {
  homePath,
  aboutPath,
  servicePath,
  contactPath,
  announcementsPath,
  privacyPolicyPath,
  refundPolicyPath,
  romanianPath,
  englishPath,
  dashboardPath,
  loginPath,
  pointsDashboardPath,
  pastServicesPath,
  serviceEditorPath,
  announcementsDashboardPath,
  newAnnouncementPath,
  pastAnnouncementsPath,
  propertiesPath,
  goLivePath,
  givePath,
  EN,
  socialPath,
  newUserPath,
  urgentMessagesDashboardPath,
  mediaPath,
  bibleReadingPlanPath,
  bibleInAYearPath,
  biblePath,
  readingPlanPath,
} from "./constants";
import English from "./languageRouters/english";
import Romanian from "./languageRouters/romanian";
import ServiceContext from "./contexts/serviceContext";
import Dashboard from "./pages/privatePages/dashboard";
import Login from "./pages/privatePages/login";
import PointsDashboard from "./pages/privatePages/pointsDashboard";
import PastServices from "./pages/privatePages/pointsAddition/pastServices";
import ServiceEditor from "./pages/privatePages/pointsAddition/serviceEditor";
import AnnouncementsDashboard from "./pages/privatePages/announcements/announcementsDashboard";
import NewAnnouncement from "./pages/privatePages/announcements/newAnnouncement";
import PastAnnouncements from "./pages/privatePages/announcements/pastAnnouncements";
import Properties from "./pages/privatePages/properties/properties";
import GoLive from "./pages/privatePages/properties/goLive";
import Give from "./pages/donations/give";
import Social from "./pages/social/social";
import NewUser from "./pages/privatePages/newUser";
import UrgentMessagesDashbaord from "./pages/privatePages/properties/urgentMessages/urgentMessages";
import Gallery from "./pages/media/gallery";
import { ServiceEditorProvider } from "./contexts/serviceEditorContext";
import BiblePlan from "./pages/biblePlan/biblePlan";
import STRIPE from "./STRIPTEMP";
import Ezra from "./pages/tempPages/Ezra";
import Awana from "./pages/tempPages/awana/Awana";

const Bethel = () => {
  // USE STATES --------------------------------------------------------
  const [menuIsActive, setIsMenuActive] = useState(false);
  const [isHamburgerClicked, setIsHamburgerClicked] = useState(false);
  const [menuHeight, setMenuHeight] = useState(56);
  const [footerHeight, setFooterHeight] = useState(43);
  const [pageSizing, setPageSizing] = useState({
    footerSize: footerHeight,
    menuSize: menuHeight,
  });
  const [serviceId, setServiceId] = useState("");
  const [serviceTimestamp, setServiceTimestamp] = useState("");
  const serviceValue = {
    serviceId,
    setServiceId,
    serviceTimestamp,
    setServiceTimestamp,
  };

  // -------------------------------------------------------------------

  /**
   * This is used whenever a menu button is clicked to ensure that after
   * a button click, the menu always closes
   */
  const closeMenu = () => {
    setIsMenuActive(false);
    setIsHamburgerClicked(false);
  };

  /**
   * Handles the menu hamburger toggle
   */
  const handleHamburger = () => {
    setIsMenuActive(menuIsActive ? false : true);
    setIsHamburgerClicked(isHamburgerClicked ? false : true);
  };

  /**
   * Sets the menu height retrieved from the menu component
   */
  const handleMenuHeight = (height) => {
    setMenuHeight(height);
    setPageSizing({
      footerSize: pageSizing.footerSize,
      menuSize: height,
    });
  };

  /**
   * Sets the footer height retreieved from the footer component
   */
  const handleFooterHeight = (height) => {
    setFooterHeight(height);
    setPageSizing({
      footerSize: height,
      menuSize: pageSizing.menuSize,
    });
  };

  return (
    <BrowserRouter>
      <ServiceContext.Provider value={serviceValue}>
        <div style={{ height: "100vh" }}>
          <Menu
            menuIsActive={menuIsActive}
            isHamburgerClicked={isHamburgerClicked}
            closeMenu={closeMenu}
            handleHamburger={handleHamburger}
            handleMenuHeight={handleMenuHeight}
          />
          <Routes>
            <Route path={homePath} exact element={<Home size={pageSizing} />} />
            <Route path={aboutPath} element={<About />} size={pageSizing} />
            <Route
              path={servicePath}
              element={<Services size={pageSizing} />}
            />
            <Route path={contactPath} element={<Contact size={pageSizing} />} />
            <Route path={givePath} element={<Give />} />
            <Route
              path={announcementsPath}
              element={<Announcements size={pageSizing} />}
            />
            <Route
              path={privacyPolicyPath}
              element={<PrivacyPolicy size={pageSizing} />}
            />
            <Route
              path={refundPolicyPath}
              element={<RefundPolicy size={pageSizing} />}
            />
            <Route path={dashboardPath} element={<Dashboard />} />
            <Route path={loginPath} element={<Login />} />
            <Route path={newUserPath} element={<NewUser />} />
            <Route path={pointsDashboardPath} element={<PointsDashboard />} />
            <Route path={pastServicesPath} element={<PastServices />} />
            <Route
              path={serviceEditorPath}
              element={
                <ServiceEditorProvider>
                  <ServiceEditor />
                </ServiceEditorProvider>
              }
            />
            <Route
              path={announcementsDashboardPath}
              element={<AnnouncementsDashboard />}
            />
            <Route path={newAnnouncementPath} element={<NewAnnouncement />} />
            <Route
              path={pastAnnouncementsPath}
              element={<PastAnnouncements />}
            />
            <Route path={propertiesPath} element={<Properties />} />
            <Route path={goLivePath} element={<GoLive />} />
            <Route path={socialPath} element={<Social />} />
            <Route
              path={urgentMessagesDashboardPath}
              element={<UrgentMessagesDashbaord />}
            />
            <Route path={mediaPath} element={<Gallery />} />
            <Route path={romanianPath} element={<Romanian />} />
            <Route path={englishPath} element={<English />} />
            {/* This route is for the Bible reading plan that will be over in 2025. Remove this then. */}
            <Route path={bibleReadingPlanPath} element={<BiblePlan />} />
            <Route path={bibleInAYearPath} element={<BiblePlan />} />
            <Route path={biblePath} element={<BiblePlan />} />
            <Route path={readingPlanPath} element={<BiblePlan />} />
            <Route path="hidden_stripe" element={<STRIPE />} />{" "}
            {/* TODO: REMOVE THIS WHEN WE'RE SETTLED */}
            <Route path="Ezra" element={<Ezra />} />{" "}
            <Route path="Awana" element={<Awana />} />
            {/* TODO: Remove this after the Ezra Conference */}
            <Route path="*" element={<ErrorPage size={pageSizing} />} />
          </Routes>
          <Footer handleClick={closeMenu} handleSizing={handleFooterHeight} />
        </div>
      </ServiceContext.Provider>
    </BrowserRouter>
  );
};

export default Bethel;
