import "./moreDropdown.css";
import { HiArrowSmDown } from "react-icons/hi";
import { NavLink } from "react-router-dom";
import { awanaPath, ezraPath, moreString } from "../../../../constants";
import { useAppSelector } from "../../../../app/hooks";
import { selectLanguage } from "../../../../app/slices/languageSlice";

const MoreDropdown = (props) => {
  const language = useAppSelector(selectLanguage);
  return (
    <div className="dropdown">
      <div className="moreTitle">
        {moreString(language)}
        <span className="arrow">
          <HiArrowSmDown />
        </span>
      </div>
      <div className="dropdown-content">
        <NavLink
          activeclassname="active"
          onClick={props.onClick}
          to={awanaPath}
        >
          Awana
        </NavLink>
        <NavLink activeclassname="active" onClick={props.onClick} to={ezraPath}>
          Ezra Conference
        </NavLink>
      </div>
    </div>
  );
};

export default MoreDropdown;
