import { Button, useTheme } from "@mui/material";
import myImage from "./BackToSundaySchool.JPG";
const Awana = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        alignItems: "center",
        paddingTop: "56px",
      }}
    >
      <h1
        style={{
          fontFamily: "Montserrat",
          color: "#666667", 
          fontWeight: "400",
          fontSize: "3vw",
        }}
      >
        Registration Is Still Open!
      </h1>
      <div
        style={{
          fontFamily: "Montserrat",
          fontSize: "2.5vw",
          marginBottom: "32px",
        }}
      >
        Join us for Awana at Bethel Bible Church every Friday at 7:00 PM!
      </div>
      <img
        src={myImage}
        style={{
          width: "70%",
          maxHeight: "70%",
          objectFit: "contain",
        }}
        alt="The Ezra Conference"
      />
    </div>
  );
};

export default Awana;

// TODO: This is a temporary page. We should update for Awana events in the future
