import { NavLink } from "react-router-dom";
import "./rightColumn.css";
import {
  announcementsPath,
  facebookPath,
  youtubePath,
  instagramPath,
  vimeoPath,
  announcementsString,
  twitterPath,
  privacyPolicyPath,
  refundPolicyPath,
  privacyPolicyString,
  refundPolicyString,
  mediaPath,
  mediaString,
  bibleReadingPlanPath,
  bibleReadingPlanString,
} from "../../../constants";
import VisuallyHiddenText from "../../../components/VisuallyHiddenText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faXTwitter,
  faYoutube,
  faVimeoV,
} from "@fortawesome/free-brands-svg-icons";
import { useAppSelector } from "../../../app/hooks";
import { selectLanguage } from "../../../app/slices/languageSlice";
import MoreDropdown from "./moreDropdown/moreDropdown";

const RightColumn = (props) => {
  const language = useAppSelector(selectLanguage);
  return (
    <div>
      <div className="linkHeaders">
        <NavLink
          activeclassname="active"
          onClick={props.handleClick}
          to={announcementsPath}
        >
          {" "}
          {announcementsString(language)}{" "}
        </NavLink>
        <NavLink
          activeclassname="active"
          onClick={props.handleClick}
          to={mediaPath}
        >
          {" "}
          {mediaString(language)}{" "}
        </NavLink>
        <NavLink
          activeclassname="active"
          onClick={props.handleClick}
          to={bibleReadingPlanPath}
        >
          {" "}
          {bibleReadingPlanString(language)}{" "}
        </NavLink>

        {/* We do not currently have "more" items. If we ever get more items, then we can uncomment the code below and add it to the more menu */}
        <MoreDropdown onClick={props.handleClick} />
      </div>

      <div className="secondarySocialContainer">
        <div className="secondarySocial">
          <a href={facebookPath} target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faFacebook} size="xs" aria-hidden="true" />
            <VisuallyHiddenText>Facebook</VisuallyHiddenText>
          </a>
          <a href={instagramPath} target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faInstagram} size="xs" aria-hidden="true" />
            <VisuallyHiddenText>Instagram</VisuallyHiddenText>
          </a>
          <a href={twitterPath} target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faXTwitter} size="xs" aria-hidden="true" />
            <VisuallyHiddenText>Twitter</VisuallyHiddenText>
          </a>
          <a href={youtubePath} target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faYoutube} size="xs" aria-hidden="true" />
            <VisuallyHiddenText>YouTube</VisuallyHiddenText>
          </a>
          <a href={vimeoPath} target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faVimeoV} size="xs" aria-hidden="true" />
            <VisuallyHiddenText>Vimeo</VisuallyHiddenText>
          </a>
        </div>
      </div>
      <div className="policy">
        <NavLink
          activeclassname="active"
          onClick={props.handleClick}
          to={privacyPolicyPath}
          style={{ fontSize: "12px" }}
        >
          {" "}
          {privacyPolicyString(language)}{" "}
        </NavLink>
        <NavLink
          activeclassname="active"
          onClick={props.handleClick}
          to={refundPolicyPath}
          style={{ fontSize: "12px" }}
        >
          {" "}
          {refundPolicyString(language)}{" "}
        </NavLink>
      </div>
    </div>
  );
};

export default RightColumn;
